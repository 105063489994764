var debug = false;
var mobile = false;

// Console Styles
var console_default = "font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; background-color: #ffffff; font-size:14px; padding:5px 0; ";
var console_app = console_default + "color: blue; background-color: #ffffff; font-size:14px;";
var console_googlemaps = console_default + "color: purple; background-color: #ffffff; font-size:14px;";
var console_threejs  = console_default + "color: red; background-color: #ffffff; font-size:14px;";
var console_ambient_video = console_default + "color: magenta; background-color: #ffffff; font-size:14px;";
var console_scroll_magic = console_default + "color: brown; background-color: #ffffff; font-size:14px;";
var console_test = console_default + "color: white; background-color: red; font-size:20px; padding:5px;";

var ambient_video_active = true;

var $ = jQuery;

/**
 * Client web browser detection and site settings based on results.
 */

var browser_detect = {
	init: function() {
		this.browser = this.searchString(this.dataBrowser) || "Other";
		this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "Unknown";

		browser_detect.settings();

		if (debug) {
			console.log("%cBrowser Detect || Browser: " + this.browser + ", Version: " + this.version, console_default);
		}
	},
	searchString: function(data) {
		var len = data.length;
		for (var i = 0; i < len; ++i) {
			var dataString = data[i].string;
			this.versionSearchString = data[i].subString;

			if (dataString.indexOf(data[i].subString) !== -1) {
				return data[i].identity;
			}
		}
	},
	searchVersion: function(dataString) {
		var index = dataString.indexOf(this.versionSearchString);
		if (index === -1) {
			return;
		}

		var rv = dataString.indexOf("rv:");
		if (this.versionSearchString === "Trident" && rv !== -1) {
			return parseFloat(dataString.substring(rv + 3));
		} else {
			return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
		}
	},

	dataBrowser: [{
		string: navigator.userAgent,
		subString: "Chrome",
		identity: "Chrome"
	}, {
		string: navigator.userAgent,
		subString: "MSIE",
		identity: "Explorer"
	}, {
		string: navigator.userAgent,
		subString: "Trident",
		identity: "Explorer"
	}, {
		string: navigator.userAgent,
		subString: "Firefox",
		identity: "Firefox"
	}, {
		string: navigator.userAgent,
		subString: "Safari",
		identity: "Safari"
	}, {
		string: navigator.userAgent,
		subString: "Opera",
		identity: "Opera"
	}],

	settings: function() {

		//http://stackoverflow.com/questions/25455432/testing-for-svg-mask-support-in-js
		//console.log(document.implementation.hasFeature("http://www.w3.org/TR/SVG11/feature#Mask", "1.0"))
		if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
			mobile = true;

			ambient_video_active = false;
		}

		if(Modernizr.touch) {
			touch = true;
		}

		if (!Modernizr.svg || !Modernizr.inlinesvg || Modernizr.svgasimg) {
			svg_active = false;
		}

		if (browser_detect.browser == "Firefox" || browser_detect.browser == "Explorer") {
			
		}

		if (browser_detect.browser == "Firefox") {

		}

		if (browser_detect.browser == "Explorer" && browser_detect.version <= "8") {
			alert("Your browser is outdated. Please upgrade to view this website.");
		}

		if (browser_detect.browser == "Explorer" && browser_detect.version <= 9) {

		}

		if (debug) {
			console.log("%cBrowser Detect // Settings", console_default);
		}

	}
	
};


function getParameterByName(name, url) {
	if (!url) url = window.location.href;
	name = name.replace(/[\[\]]/g, "\\$&");
	var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
		results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, " "));
}


/**
 * General App Controller
 */

var app = {
	page: '',
	firstsize: true,
	orientationchanged: false,
	delayedloaded: false,
	init: function() {

		if (debug) {
			console.log("%cApp // Init", console_app);
		}

		browser_detect.init();

		if (mobile && browser_detect.browser == "Safari") {
				$("body").addClass("ios");
		}

		viewport;

		this.update();
		this.size_content();
		modal.init();
		this.mouse_events();

		$(".menu_button").on("click", function(e) {
			$("body").addClass("menu_open");
		});

		$(".close_button").on("click", function(e) {
			 $("body").removeClass("menu_open");
		});

		// If the empty cart query string is found then remove it and refresh the page to prevent add to cart errors.
		if(getParameterByName('empty-cart')) {
			var cleanedUrl = window.location.href.split("?")[0];
			window.location.href = cleanedUrl;
		}

	},
	update: function() {
		if (debug) {
			console.log("%cApp // Update", console_app);
		}

		window_width = $(window).width();
		window_height = $(window).height();

		this.size_content();
	},
	size_content: function() {
		if (debug) {
			console.log("%cApp // Size Content", console_app);
		}

		function viewportHeight() {
			return window.innerHeight == null ? $(window).height() : window.innerHeight;
		}

		if (mobile) {
			if (viewport.is('xs') || viewport.is('sm')) {
				if(app.firstsize || app.orientationchanged) {

					if (debug) {
						console.log("%cApp // Phone Force Heights", console_app);
						console.log("%cApp // Phone Force Heights - Orientation Changed", console_app);
					}

					$('.splash').height($('.splash').outerHeight());

					app.firstsize = false;
				}
			}
		}

		app.orientationchanged = false;

	},
	clear_sizing: function() {
		if (debug) {
			console.log("%cApp // Clear Sizing", console_app);
		}

		app.orientationchanged = true;

		// RESET STYLES TO CSS TO FIX VH UNITS
		$(".splash").removeAttr( 'style' );
	},
	mouse_events: function() {

		if (debug) {
			console.log("%cApp // Mouse Events", console_app);
		}


		$(document).ready(function () {
			if($('.single-location').length) {

				// If there is donuts in the cart and the user navigates to a location page check the user's cookie to make sure they are looking at the location that they added the donuts to their cart at. If not display a message.
				if($('.cart_count').length) {
					var numberInCart = parseInt($('.cart_count').text());
					var currentCookie = Cookies.get('glamdoll_location_id');
					//console.log(currentCookie);

					if(post_id != currentCookie) {
						$(".modal").show();
					}

				} else {
					Cookies.set('glamdoll_location_id', post_id, { expires: 2, path: '/' });
				}

			} else if($('.single-product').length) {
				var currentCookie = Cookies.get('glamdoll_location_id');
				$(".location_needed").hide();
				$('.location_' + currentCookie).css('display', 'inline-block');
				//console.log("SHOW .location_" + currentCookie)
			}
		});

		// Makes LETTER & NUMBER DONUTS message all caps
		// Staging, local, and production product post ids
		$('#product-362 input.tmcp-textfield, #product-471 input.tmcp-textfield, #product-1391 input.tmcp-textfield').keyup(function() {
			this.value = this.value.toLocaleUpperCase();
		});
	}
};


var modal = {
	active: false,
	init: function() {
		if ($('.modal').length) {
			modal.clickEvents();
		}
	},
	clickEvents: function() {
		$("[data-modal]").click(function() {
			var modal_id = $(this).attr("data-modal");
			$('.modal').fadeIn("slow");
			$('.' + modal_id).fadeIn("slow");

			if($(this).attr("data-modal-url")) {
				$('.' + modal_id + " iframe").attr('src', $(this).attr("data-modal-url"));
			}
		});

		$(".modal").click(function() {
			if(!$('.single-location').length) {
				$('.modal').stop().fadeOut();
				$('.modal_item').stop().fadeOut();
				$('.modal iframe').attr('src', "");
			}
		});

		function closeModal() {
			$('.modal').stop().fadeOut();
			$('.modal_item').stop().fadeOut();
			$('.modal iframe').attr('src', "");
		}

		$(".modal .close_modal").click(function() {
			closeModal();
		});

		$(".modal .modal_background").click(function() {
			if(!$(".modal").hasClass("permanent")) {
				closeModal();
			}
		});

		$('.modal_item_content').click(function(event){
			event.stopPropagation();
		});
	}
};



// Listen for orientation changes
window.addEventListener("orientationchange", function() {

	setTimeout(app.clear_sizing, 50);
	setTimeout(app.size_content, 200);

}, false);


(function($,sr){

  // debouncing function from John Hann
  // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
  var debounce = function (func, threshold, execAsap) {
	  var timeout;

	  return function debounced () {
		  var obj = this, args = arguments;
		  function delayed () {
			  if (!execAsap)
				  func.apply(obj, args);
			  timeout = null;
		  };

		  if (timeout)
			  clearTimeout(timeout);
		  else if (execAsap)
			  func.apply(obj, args);

		  timeout = setTimeout(delayed, threshold || 100);
	  };
  }
  // smartresize 
  jQuery.fn[sr] = function(fn){  return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr); };

})(jQuery,'smartresize');




/**
 * Determine the currently active Bootstrap screen size. Used for running breakpoint dependant JS.
 */

/*!
 * Responsive Bootstrap Toolkit
 * Author:    Maciej Gurban
 * License:   MIT
 * Version:   2.2.0 (2015-01-06)
 * Origin:    https://github.com/maciej-gurban/responsive-bootstrap-toolkit
 */

var viewport = (function($){

	// Methods and properties
	var self = {

		// Determines interval between firing 'changed' method
		interval: 300,

		// Breakpoint aliases
		breakpoints: [
			'xs', 'sm', 'md', 'lg', 'xl'
		],

		// Used to calculate intervals between consecutive function executions
		timer: new Date(),

		// Returns true if current breakpoint matches passed alias
		is: function( alias ) {
			return $('.device-' + alias).is(':visible');
		},

		// Returns current breakpoint alias
		current: function(){
			var name = 'unrecognized';
			self.breakpoints.forEach(function(alias){
				if(self.is(alias)) {
					name = alias;
				}
			});
			return name;
		},

		/* 
		 * Waits specified number of miliseconds before executing a function
		 * Source: http://stackoverflow.com/a/4541963/2066118
		 */
		changed: function() {
			var timers = {};
			return function (callback, ms) {
				// Get unique timer ID
				var uID = (!uID) ? self.timer.getTime() : null;
				if (timers[uID]) {
					clearTimeout(timers[uID]);
				}
				// Use default interval if none specified
				if(typeof ms === "undefined") {
					var ms = self.interval;
				}
				timers[uID] = setTimeout(callback, ms);
			};
		}()

	}

	return self;

})(jQuery);


/**
 * Console log out the currently active Bootstrap screen size
 */

$(window).resize(function() {
	viewport.changed(function() {

		if (viewport.is('xs')) {
			if (debug) {
				console.log("%cScreen Size // XS", console_default);
			}
		}

		if (viewport.is('sm')) {
			if (debug) {
				console.log("%cScreen Size // SM", console_default);
			}
		}

		if (viewport.is('md')) {
			if (debug) {
				console.log("%cScreen Size // MD", console_default);
			}
		}

		if (viewport.is('lg')) {
			if (debug) {
				console.log("%cScreen Size // LG", console_default);
			}
		}

		if (viewport.is('xl')) {
			if (debug) {
				console.log("%cScreen Size // XL", console_default);
			}
		}

	})
});


/**
 * Fix random resize issues
 */

function doneResizing(){
	app.update();    
}


//// INITIALIZE ////

$(document).ready(function() {
	app.init();
});

$(window).resize(function() {
	app.update();

	var resizeId;
	clearTimeout(resizeId);
	resizeId = setTimeout(doneResizing, 500);
});
